const lang= localStorage.getItem("lang");
const initialState ={
  lang:lang , 
  staticUrl : '',
  jsonData : []
}
export const countReducer = (state = initialState, action) => {
    switch (action.type) {
      case "CHANGE_lANG":
        return { ...state, lang : action.payload }
      case 'SET_STATIC_URL':
        return { ...state, staticUrl: action.payload }
      case 'SET_JSON_DATA':
        return { ...state, jsonData: action.payload }
      default:
        return state;
    }
};